import { configureStore } from '@reduxjs/toolkit';
import adminSlice from './slices/adminSlice';
import layoutSlice from './slices/layoutSlice';

export const store = configureStore({
  reducer: {
    admin: adminSlice,
    layout: layoutSlice,
  },
});
