import React from 'react';
import { Navigate } from 'react-router-dom';
import auth from '../hooks/auth';

function AuthRoute({ children }) {
  if (!auth.getUser()) {
    location.href = process.env.REACT_APP_URL + process.env.REACT_APP_BASENAME + '/login';
    return <></>;
  }
  return children;
}

export default AuthRoute;
