const getLocalAccessToken = () => {
  return sessionStorage.getItem('adminAccessToken');
};

const setLocalAccessToken = (token) => {
  sessionStorage.setItem('adminAccessToken', token);
};

const removeUser = () => {
  sessionStorage.removeItem('adminAccessToken');
};

const tokenService = {
  // setLocalRefreshToken,
  // getLocalRefreshToken,
  getLocalAccessToken,
  removeUser,
  setLocalAccessToken,
};

export default tokenService;
