import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    id: 0,
    username: '',
    level: 0,
    name: '',
  },
  reducers: {
    login(state, action) {
      state = action.payload;
    },
    logout(state) {
      state = {};
    },
    isLogin(state) {
      return state.id !== 0;
    },
  },
});

export const actions = adminSlice.actions;
export default adminSlice.reducer;
