import dayjs from 'dayjs';
import React, { Component, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import './scss/style.scss';

const queryClient = new QueryClient();

dayjs.locale('ko');

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));
const Page404 = React.lazy(() => import('./views/errors/Page404'));
const Page500 = React.lazy(() => import('./views/errors/Page500'));

class App extends Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route
                path="*"
                name="Home"
                element={
                  <AuthRoute>
                    <DefaultLayout />
                  </AuthRoute>
                }
              />
            </Routes>
          </Suspense>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    );
  }
}

export default App;
