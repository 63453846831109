import { createSlice } from '@reduxjs/toolkit';

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    sidebarShow: true,
    asideShow: false,
    theme: 'default',
  },

  reducers: {
    changeTheme(state, action) {
      state.theme = action.payload;
    },

    changeAsideShow(state, action) {
      state.asideShow = action.payload;
    },

    changeSidebarShow(state, action) {
      state.sidebarShow = action.payload;
    },
  },
});

export const actions = layoutSlice.actions;
export default layoutSlice.reducer;
